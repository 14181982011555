<script lang="ts" setup>
import IssueActiveStep from './IssueActiveStep.vue';
import IssueActivePhaseButtonNav from './IssueActivePhaseButtonNav.vue';
import IssueActiveInformationPhase from './IssueActiveInformationPhase.vue';
import useProcessObservers from '@/features/issueProcess/composables/useProcessObservers';
import { ProcessOperation, useProcessStore } from '@/features/issueProcess/composables/useProcessStore';
import useActivePhaseOperations from '@/features/issueProcess/composables/useActivePhaseOperations';
import Translate from '@/features/translations/Translate.vue';
import { ref, computed, watch, onUnmounted, onMounted } from 'vue';
import { IssuePhaseStepEnum, useDeleteFileMutation, useDeletePhaseStepFieldMutation, useGetCurrentPhaseValidationViewModelQuery } from '@/generated/graphql';
import useInputFiles from '@/features/files/composables/useInputFiles';
import { UpdatePhaseInput } from '@/features/issueProcess/models/UpdatePhaseInput';
import { IssueDetails } from '@/features/issues/models';
import useActiveIssue from '@/features/issueProcess/composables/useActiveIssue';
import { Delta } from '@vueup/vue-quill';
import dateAdd from 'date-fns/add';
import IssueSaveWarning from '@/features/issues/components/IssueSaveWarning.vue';
import { onBeforeRouteLeave, useRouter, RouteLocationNormalized } from 'vue-router';

const props = defineProps<{
  activeIssue: IssueDetails;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'step', stepId: ID): void;
  (e: 'prevStep', stepId: ID): void;
  (e: 'communicationStep', selectedStepType: IssuePhaseStepEnum): void;
  (e: 'next'): void;
  (e: 'prev'): void;
  (e: 'publish'): void;
  (e: 'save'): void;
  (e: 'draft'): void;
  (e: 'continue'): void;
}>();

const activeIssue = computed(() => props.activeIssue);
const { selectedPhase, basicInfoPhaseSelected, selectedStep, isNextStepReviews } = useActiveIssue(activeIssue);
const { publishActivePhase, closeActivePhase, draftActivePhase, updateSelectedIssuePhase, getStepPhaseFiles, addFileToPhase } =
  useActivePhaseOperations(activeIssue);

const { executeQuery } = useGetCurrentPhaseValidationViewModelQuery({
  requestPolicy: 'network-only',
  pause: true,
  variables: computed(() => ({ issueId: props.activeIssue.id.toString() })),
});

const stepPhaseFiles = computed(() => {
  if (selectedStep.value) {
    return getStepPhaseFiles(selectedStep.value.type, selectedPhase?.value?.type);
  }
  return getStepPhaseFiles();
});

const { executeMutation: deleteFile } = useDeleteFileMutation();

const { executeMutation: deletePhaseStepField } = useDeletePhaseStepFieldMutation();

const { garbageFiles, inputAssets, newFiles, onFileDelete, onFileRestore, onFilesInput, onNewFileCancel, onNewFileEdit, remoteFiles, clearInputAssets } =
  useInputFiles(stepPhaseFiles);

const issuePhaseInput = ref<UpdatePhaseInput>({});

const skipStatutoryReviewerValidation = computed(() => {
  return isNextStepReviews.value && issuePhaseInput.value.skipStatutoryReviewerValidation;
});

const onProcessPublish = async () => {
  const response = await executeQuery();
  const activePhase = response.data.value?.issue.currentPhase;
  if (!activePhase) {
    return;
  }

  const hasReviewers = activePhase.requiredReviewers?.length || activePhase.extraRequiredReviewers?.length;
  if (activePhase.isStatutoryReviewersRequired && !hasReviewers && !activePhase.skipStatutoryReviewerValidation) {
    return;
  }

  await publishActivePhase();
};

const onProcessClose = async () => {
  await closeActivePhase();
};

const onProcessDraft = async () => {
  await draftActivePhase();
};

const onCommentDelete = async (stepType: IssuePhaseStepEnum, iterationCounter: number) => {
  if (selectedPhase.value?.id) {
    await deletePhaseStepField({ phaseId: selectedPhase.value?.id.toString(), stepType: stepType, iterationCounter: iterationCounter });
  }
};

const onProcessSave = async () => {
  if (!basicInfoPhaseSelected.value) {
    for (const file of garbageFiles.value) {
      const fid = file.id.toString();
      const { error } = await deleteFile({ fileId: fid });

      if (!error) {
        onFileRestore(file, -1);
      }
    }

    await updateSelectedIssuePhase({
      ...issuePhaseInput.value,
    });

    const currentStep = selectedStep.value;
    for (const inputFile of inputAssets.value) {
      await addFileToPhase({ ...inputFile, stepType: currentStep?.type!, phaseId: issuePhaseInput.value.phaseId! });
    }
    clearInputAssets();
  }
};

const { subscribeToProcessOp } = useProcessObservers();

const saveSub = subscribeToProcessOp(ProcessOperation.Save);
const pubSub = subscribeToProcessOp(ProcessOperation.Publish);
const closeSub = subscribeToProcessOp(ProcessOperation.Close);
const draftSub = subscribeToProcessOp(ProcessOperation.Draft);

watch(saveSub, (value, oldValue) => {
  if (value !== oldValue) {
    onProcessSave();
  }
});

watch(pubSub, (value, oldValue) => {
  if (value !== oldValue) {
    onProcessPublish();
  }
});

watch(closeSub, (value, oldValue) => {
  if (oldValue !== value) {
    onProcessClose();
  }
});

watch(draftSub, (value, oldValue) => {
  if (value !== oldValue) {
    onProcessDraft();
  }
});

const onClose = () => {
  emits('close');
};

const onNext = () => {
  processStore.isDirty = false;
  emits('next');
};

const onPrev = () => {
  emits('prev');
};

const onStep = (stepId: ID) => {
  emits('step', stepId);
};

const onPrevStep = (stepId: ID) => {
  emits('prevStep', stepId);
};

const onCommunicationStep = (selectedStepType: IssuePhaseStepEnum) => {
  onPublish();
  emits('communicationStep', selectedStepType);
};

const onPublish = () => {
  processStore.isDirty = false;
  emits('publish');
};

const onDraft = () => {
  emits('draft');
};

const onSave = () => {
  warningPrompt.value = false;
  processStore.isDirty = false;
  emits('save');
};

const onCancel = () => {
  warningPrompt.value = false;
};

const intended = ref<RouteLocationNormalized | null>(null);
const router = useRouter();

const onContinue = () => {
  warningPrompt.value = false;
  processStore.isDirty = false;

  if (intended.value) {
    router.push(intended.value);
    emits('continue');
  }
};

const isFileStep = computed<boolean>(() => {
  if (!selectedStep.value) {
    return false;
  }

  const fileSteps: IssuePhaseStepEnum[] = [
    IssuePhaseStepEnum.FyrirliggjandiGogn,
    IssuePhaseStepEnum.GognTilStadfestingar,
    IssuePhaseStepEnum.InnsetningLokagagna,
    IssuePhaseStepEnum.NidurstadaUmsagnarferlis,
    IssuePhaseStepEnum.StadfestingSkipulagsstofnunar,
    IssuePhaseStepEnum.TillagaTilAthugunar,
    IssuePhaseStepEnum.UmsognSkipulagsstofnunar,
  ];

  return fileSteps.includes(selectedStep.value.type);
});

const hasStepFiles = computed<boolean>(() => {
  if (isFileStep.value) {
    return newFiles.value.length > 0 || remoteFiles.value.length > 0;
  }

  return true;
});

watch(
  selectedPhase,
  newValue => {
    if (newValue) {
      const extraReviewersInit = newValue.extraRequiredReviewers.map(({ name, email }) => ({ name, email }));
      const { richTextDescription: maybeDelta, description } = newValue;

      let ops = [];
      if (maybeDelta) {
        ops = JSON.parse(maybeDelta);
      } else if (description) {
        ops.push({ insert: description });
      }
      issuePhaseInput.value = {
        phaseId: newValue.id.toString(),
        title: newValue.title,
        description: newValue.description,
        statutoryReviewerIds: newValue.requiredReviewers.map(({ id }) => id.toString()),
        extraRequiredReviewers: extraReviewersInit.length > 0 ? extraReviewersInit : [],
        reviewStartDate: newValue.reviewStartDate ? new Date(newValue.reviewStartDate) : new Date(),
        reviewEndDate: newValue.reviewEndDate ? new Date(newValue.reviewEndDate) : dateAdd(new Date(), { weeks: 4 }),
        quillDelta: new Delta(ops),
        skipStatutoryReviewerValidation: newValue.skipStatutoryReviewerValidation,
      };
    }
  },
  {
    immediate: true,
  }
);

const warningPrompt = ref<boolean>(false);
const processStore = useProcessStore();

const nUpdates = ref(0);

const onIssuePhaseInput = (input: UpdatePhaseInput) => {
  nUpdates.value = nUpdates.value + 1;
  processStore.isDirty = nUpdates.value > 1;

  issuePhaseInput.value = {
    ...issuePhaseInput.value,
    ...input,
  };
};

onMounted(() => {
  addEventListener('beforeunload', windowUnloadListener, { capture: true });
});

onUnmounted(() => {
  removeEventListener('beforeunload', windowUnloadListener);
});

const windowUnloadListener = (event: BeforeUnloadEvent) => {
  if (processStore.isDirty) {
    event.returnValue = 'Are you sure you want to leave';
  }
};

onBeforeRouteLeave(to => {
  intended.value = to;

  if (processStore.isDirty) {
    warningPrompt.value = true;
    return false;
  }
});
</script>

<template>
  <div class="mb-2">
    <IssueActiveInformationPhase
      :active-issue="activeIssue"
      v-if="basicInfoPhaseSelected"
    />
    <IssueActiveStep
      v-else-if="selectedPhase"
      :active-issue="activeIssue"
      :input="issuePhaseInput"
      :garbage-files="garbageFiles"
      :new-files="newFiles"
      :own-files="remoteFiles"
      @update:input="onIssuePhaseInput"
      @on-file-delete="onFileDelete"
      @on-files-input="onFilesInput"
      @on-file-restore="onFileRestore"
      @on-new-file-cancel="onNewFileCancel"
      @on-new-file-edit="onNewFileEdit"
      @on-comment-delete="onCommentDelete"
    />
    <div v-else><Translate t="issue.phase.missing" /></div>
  </div>
  <IssueActivePhaseButtonNav
    :active-issue="activeIssue"
    @close="onClose"
    @next="onNext"
    @prev="onPrev"
    @step="onStep"
    @prev-step="onPrevStep"
    @communication-step="onCommunicationStep"
    @publish="onPublish"
    @save="onSave"
    @draft="onDraft"
    :file-warning="!hasStepFiles"
    :hasGarbageFiles="garbageFiles.length > 0"
    :skipStatutoryReviewerValidation="skipStatutoryReviewerValidation"
  />
  <IssueSaveWarning
    :prompt="warningPrompt"
    @continue="onContinue"
    @save="onSave"
    @cancel="onCancel"
  ></IssueSaveWarning>
</template>

<style lang="scss" scoped>
.phase-container {
  background: #fff;
  margin-bottom: 2rem;
}
</style>
