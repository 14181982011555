<script lang="ts" setup>
import LayoutSplit from '@/features/theme/base/layouts/LayoutSplit.vue';
import IssueMap from '@/features/issues/components/issueMap/IssueMap.vue';
import IssueCardList from '@/features/issues/components/issueList/IssueCardList.vue';
import { computed, ref, onMounted, nextTick, watch } from 'vue';
import useIssueStore from '@/features/issues/composables/useIssueStore';
import useWatchedIssues from '@/features/issues/composables/useWatchedIssues';
import useAuthUser from '@/features/auth/useAuthUser';
import DropDown from '@/features/theme/base/DropDown.vue';
import LoginPrompt from '@/features/auth/LoginPrompt.vue';
import { IssueSortEnum } from '@/generated/graphql';
import Translate from '@/features/translations/Translate.vue';
import Option from '@/features/theme/base/models/Option';
import { useI18n } from 'vue-i18n';
import Spinner from '@/features/theme/base/Spinner.vue';
import useNavigationStore from '@/features/navigation/useNavigationStore';
import IssueMapCta from './IssueMapCta.vue';
import { useRoute } from 'vue-router';
import Combobox from '@/features/theme/base/combobox/Combobox.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { useRouteQuery } from '@vueuse/router';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import useInfiniteScrollingIssueCards from '../../composables/useInfiniteScrollingIssueCards';
import useCommunityFilterModels from '@/features/communities/composables/useCommunityFilterModels';
import { IssueCardViewModel } from '../../models';

const store = useIssueStore();

const cardListContainerRef = ref<HTMLElement | null>(null);
const { issueCards, mapIssues, isInitialized, totalCount } = useInfiniteScrollingIssueCards(cardListContainerRef);

watch(computed(() => store.issueSearch), () => {
  mapDrawKey.value += 1;
  map.value.updateMap(3);
  map.value.deselect();

  if (!store.issueSearch.spec.community) {
    selected.value = undefined;
    communitySelectedOption.value = undefined;
    communityInput.value = '';
  }
});

const mapDrawKey = ref(0);

const selected = ref<string>();
const onIssueSelected = (issue: IssueCardViewModel) => {
  selected.value = issue.id.toString();
};

const onIssueMapSelected = (issueId: string) => {
  selected.value = issueId;
};

const onIssueDeselected = () => {
  selected.value = undefined;
};

const { isAuthenticated } = useAuthUser();
const { toggleWatchIssue } = useWatchedIssues();
const onIssueBookmark = (issueId: string) => {
  if (isAuthenticated.value) {
    toggleWatchIssue(issueId);
  } else {
    loginPrompt.value = true;
  }
};

const onSpecificationClear = () => {
  store.resetIssueSpecification();
};

const route = useRoute();
onMounted(() => {
  if (!route.query.search) {
    onSpecificationClear();
  }
  if (communityInput.value) {
    const spec = { ...store.issueSearch.spec, community: communityInput.value };
    store.patchIssueSpecification({ ...store.issueSearch, spec: spec });
  }
});

const loginPrompt = ref<boolean>(false);
const onLoginCancel = () => {
  loginPrompt.value = false;
};

const { communitiesExcludingDisabled: communities } = useCommunityFilterModels();
const communityOptions = computed(() => communities.value.map((community) => community.name));
const communityInput = ref(route.query.community?.toString() || '');
const communitySelectedOption = useRouteQuery<Maybe<string>>('community');
const onCommunityOptionSelected = (option: string) => {
  map.value.updateMap(3);
  selected.value = undefined;
  communitySelectedOption.value = option;
  const spec = { ...store.issueSearch.spec, community: option };
  store.patchIssueSpecification({ ...store.issueSearch, spec: spec });
};

const { t } = useI18n();
const sortOptions = computed<Option[]>(() => {
  return ['new', 'updated', 'expiring'].map((opt) => ({
    name: t(`issues.sort.${opt}`),
    value: opt,
  }));
});

const selectedSortOption = ref<'new' | 'updated' | 'expiring'>('updated');
const selectedSortOptionEnum = computed<IssueSortEnum>(() => {
  if (selectedSortOption.value === 'new') {
    return IssueSortEnum.Created;
  } else if (selectedSortOption.value === 'updated') {
    return IssueSortEnum.LastUpdated;
  } else if (selectedSortOption.value === 'expiring') {
    return IssueSortEnum.ReviewDateExpiring;
  }

  return IssueSortEnum.LastUpdated;
});

const onSortOptionChange = (opt: string) => {
  if (opt !== selectedSortOption.value) {
    if (opt === 'new' || opt === 'updated' || opt === 'expiring') {
      selectedSortOption.value = opt;
    }
    const spec = { ...store.issueSearch.spec, sortBy: selectedSortOptionEnum.value };
    store.patchIssueSpecification({ ...store.issueSearch, spec: spec });
  }
};

const navStore = useNavigationStore();
navStore.setMainMenuMargin(false);

const mapView = ref<boolean>(false);
const listView = ref<boolean>(false);

const onCtaClick = () => {
  // Stop forcing full screen list
  listView.value = false;
};

const onCtaMobileClick = () => {
  // Explicit full screen map
  mapView.value = true;
  listView.value = false;
  nextTick(() => {
    if (map.value) {
      map.value.updateMap(2);
    }
  });
};

const onMapHide = () => {
  // Explicit full screen list
  listView.value = true;
  mapView.value = false;
};

const map = ref();
const onMapExpand = (expand: boolean) => {
  // Toggle full screen map or split view
  mapView.value = expand;
  nextTick(() => {
    if (map.value) {
      map.value.updateMap();
    }
  });
};

</script>

<template>
  <LayoutSplit
    class="layout"
    :column="listView || mapView"
    :class="{ 'layout--map': mapView }"
  >
    <template #left>
      <div
        class="pt-4 left-container"
        v-if="!mapView"
        :class="{ focused: listView, 'list--mobile': mapView }"
      >
        <div
          v-if="isInitialized"
          class="issue-list-container"
        >
          <div class="card-list-top-container">
            <div class="grid-item">
              <span
                v-if="!store.issueSearch.spec.search"
                class="bold mb-1"
              >
                <Translate t="issue.index.results" />
              </span>
              <span v-else>
                <Translate t="issue.index.results_for" />
                &quot;
                <span class="bold">{{ store.issueSearch.spec.search }}</span>
                &quot;
              </span>
              <div class="text-grey-600">
                <Translate
                  t="issue.index.results.interpolated"
                  :interpolations="{ n: totalCount }"
                />
              </div>
            </div>
            <div class="grid-item">
              <Button
                :type="ButtonType.secondary"
                @click="onSpecificationClear"
                :disabled="store.hasNoSpecification"
              >
                <Translate t="search.clear_filters" />
                <Icon
                  :options="{ color: store.hasNoSpecification ? Colors.grey200 : Colors.primaryDark }"
                  class="icon icon-right"
                  icon="Refresh"
                />
              </Button>
            </div>
            <div class="grid-item">
              <Combobox
                label="base.community.filter"
                :options="communityOptions"
                v-model="communityInput"
                @option:selected="onCommunityOptionSelected"
                scroll="small"
                class="combobox"
              ></Combobox>
            </div>
            <div class="grid-item">
              <DropDown
                fullwidth
                :options="sortOptions"
                label="Raða eftir"
                :model-value="selectedSortOption"
                @update:model-value="onSortOptionChange"
              />
            </div>
          </div>
          <div
            ref="cardListContainerRef"
            class="card-list-container scrollbar"
            :class="{ 'has-filters': !store.hasNoSpecification }"
          >
            <IssueCardList
              v-if="issueCards.length > 0"
              @select="onIssueSelected"
              @deselect="onIssueDeselected"
              :selected="selected"
              :issues="issueCards"
              @bookmark="onIssueBookmark"
              card-style="margin-small"
              class="mb-3"
            />
            <div v-else>
              <Translate t="search.results.none" />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-center flex-column"
        >
          <Spinner />
          <Translate t="base.loading" />
        </div>
        <div class="issue-map-cta">
          <IssueMapCta
            v-if="listView"
            @click="onCtaClick"
            class="issue-map-cta--laptop"
          />
          <IssueMapCta
            v-if="!mapView"
            @click="onCtaMobileClick"
            class="issue-map-cta--mobile"
            type="show"
          />
        </div>
      </div>
      <LoginPrompt
        :prompt="loginPrompt"
        @cancel="onLoginCancel"
      >
        <template #help>
          <Translate t="monitoring.prompt" />
        </template>
      </LoginPrompt>
    </template>
    <template #right>
      <IssueMap
        ref="map"
        class="map"
        :class="{ focused: mapView, 'map--mobile': !mapView }"
        v-if="!listView"
        @hide="onMapHide"
        @expand="onMapExpand"
        @select="onIssueMapSelected"
        @deselect="onIssueDeselected"
        :issue-id="selected"
        :issues="mapIssues"
        expandable
        :expanded="mapView"
        :hideable="!listView && !mapView"
        :legend="mapView"
        :draw-key="mapDrawKey"
      />
    </template>
  </LayoutSplit>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/layout' as layout;
@use '@/scss/design-tokens/media-queries' as mq;

$filter-container-height: min(10%, 6.4rem);

.map {
  height: 100%;
  width: 100%;

  &.focused {
    height: layout.$main-height;
  }

  @include mq.mobile() {
    height: layout.$main-height;
  }

  &--mobile {
    @include mq.laptop-down() {
      display: none;
    }
  }
}

.combobox {
  display: flex;
  flex-grow: 1;
}

.layout {
  padding-right: 2rem;
  padding-left: 2rem;

  @include mq.laptop() {
    padding: 0;
  }

  &--map {
    padding: 0;
  }
}

.issue-map-cta {
  position: sticky;
  display: flex;
  flex-direction: row-reverse;
  bottom: 2.4rem;

  &--laptop {
    display: none;
    right: calc(9.6rem + 0.6rem);

    @include mq.laptop() {
      display: flex;
    }
  }

  &--mobile {
    display: flex;
    right: 0.6rem;

    @include mq.laptop() {
      display: none;
    }
  }
}

.left-container {
  height: layout.$main-height;
  box-sizing: border-box;
  @include mq.laptop() {
    padding-left: 9.6rem;
  }

  &.focused {
    @include mq.laptop() {
      padding-right: 9.6rem;
    }
  }
}

.list--mobile {
  @include mq.mobile() {
    display: none;
  }
}

.issue-list-container {
  height: 100%;
}

.card-list-top-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
  
  @include mq.mobile() {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }
}

.grid-item {
  display: inline-flex;
  flex-direction: column;

  @include mq.mobile() {
    padding: 0.5rem;
    width: 90%;
  }
}

.card-list-container {
  max-height: calc(100% - $filter-container-height - 5rem);
  padding-right: 0;
  overflow: hidden auto;

  @include mq.mobile {
    max-width: calc(100vw - 4rem);
    max-height: calc(100% - $filter-container-height - 15rem);
  }
}

</style>
