import { computed, unref } from 'vue';
import { IssuePhase } from '../models';
import orderBy from 'lodash-es/orderBy';

export default function useIssuePhaseSteps(phase: MaybeRef<IssuePhase>) {
  const orderedSteps = computed(() => orderBy(unref(phase).steps, (step) => step.order, 'asc'));

  return {
    orderedSteps,
  };
}
