import useDateFormatting from '@/features/composables/useDateFormatting';
import { computed, ComputedRef } from 'vue';
import { UseIssueViewModel } from '../models';

interface UseIssue {
  issuePublishDate: ComputedRef<Maybe<Date>>;
  issuePublishDateFormatted: ComputedRef<string>;
  issueNumberFormatted: ComputedRef<string>;
}

export default function useIssue(issue: ComputedRef<UseIssueViewModel>): UseIssue {
  const { formatDateLocalized } = useDateFormatting();

  const issueNumberFormatted = computed(() => {
    const split = issue.value.issueNumber.split('/');
    return `${parseInt(split[0])}/${split[1]}`;
  });
  const issuePublishDate = computed(() => {
    const date = new Date(issue.value.createdDate);
    if (isNaN(date.valueOf())) {
      return null;
    }
    return date;
  });
  const issuePublishDateFormatted = computed(() => (issuePublishDate.value ? formatDateLocalized(issuePublishDate.value) : ''));

  return {
    issuePublishDate,
    issuePublishDateFormatted,

    issueNumberFormatted,
  };
}
