<script lang="ts" setup>
import Feature from 'ol/Feature';
import useVectorSource from '@/features/map/composables/useVectorSource';
import { onMounted, onUnmounted, watch } from 'vue';
import Geometry from 'ol/geom/Geometry';
import { default as FeatureType } from 'ol/Feature';

const props = defineProps<{
  feature: Feature;
  featuresToRemove?: Maybe<FeatureType<Geometry>[]>;
}>();
const getVectorSource = useVectorSource();

const emits = defineEmits<{
  (e: 'add', feature: Feature): void;
  (e: 'remove', feature: Feature): void;
  (e: 'change', feature: Feature): void;
}>();

const addFeature = (feature: Feature) => {
  getVectorSource().addFeature(feature);
  emits('add', feature);
};

const removeFeature = (feature: Feature) => {
  getVectorSource().removeFeature(feature);
  emits('remove', feature);
};

onMounted(() => {
  addFeature(props.feature);
});

onUnmounted(() => {
  if (getVectorSource().getFeatures().length <= 1) {
    removeFeature(props.feature);
  }
});

watch(
  () => props.featuresToRemove,
  // remove only the features in "FeaturesToRemove" list
  (value) => {
    if (value && value.length >= 0) {
      value?.forEach((item) => {
        if (getVectorSource().getFeatures().includes(item)) {
          removeFeature(item);
          emits('change', item);
        }
      });
    }
  }
);
</script>
<template>
  <slot />
</template>
