import { useGetIssueTagsQuery, IssueTagTypes } from '@/generated/graphql';
import groupBy from 'lodash-es/groupBy';
import { computed } from 'vue';

export default function useIssueTags(tagType?: IssueTagTypes) {
  const { data, error, fetching } = useGetIssueTagsQuery();

  const tags = computed(() => {
    let _tags = data.value?.issueTags || [];
    if (tagType) {
      _tags = _tags.filter((t) => t.tagType === tagType);
    }

    return _tags.map((t) => t.value);
  });
  const tagsLoading = computed(() => fetching.value);
  const tagsError = computed(() => error.value);
  const tagsByType = computed(() => groupBy(data.value?.issueTags || [], 'tagType'));

  return {
    tags,
    tagsLoading,
    tagsError,
    tagsByType,
  };
}
