<script lang="ts" setup>
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import Article, { ArticleText, ArticleHeader, ArticleBody, ArticleTop } from '@/features/theme/base/article';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Alert from '@/features/theme/base/Alert.vue';
import Button from '@/features/theme/base/Button.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import FileList from '@/features/files/components/FileList.vue';
import Icon from '@/features/theme/base/Icon.vue';
import Translate from '@/features/translations/Translate.vue';
import type { FilesZipInput } from '@/features/files/models/FilesZipInput';
import useDownloadFiles from '@/features/files/composables/useDownloadFiles';
import useIssuePhaseSteps from '../../composables/useIssuePhaseSteps';
import { computed } from 'vue';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import RichText from '@/features/theme/base/richTextEditor/RichText.vue';
import CommunicationPhaseHistoryGroup from '@/features/issueProcess/components/phaseSteps/CommunicationPhaseHistoryGroup.vue';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
}>();
const { getArchivesFilesUrl, downloadFileUrl } = useDownloadFiles();

const publisedPhaseFiles = computed(() => props.phase.files.filter((f) => f.published) || []);

const onClick = async () => {
  const input: FilesZipInput = {
    filePaths: publisedPhaseFiles.value.map((f) => f.data.path),
    zipName: `${props.phase.title} Gögn`,
  };

  const url = getArchivesFilesUrl(input);
  downloadFileUrl(url);
};

const { orderedSteps } = useIssuePhaseSteps(computed(() => props.phase));
const { getPhaseStepKey } = useProcessTranslation();
</script>

<template>
  <Article>
    <ArticleTop v-if="$slots.top">
      <slot name="top" />
    </ArticleTop>
    <ArticleHeader>{{ phase.title }}</ArticleHeader>
    <ArticleBody>
      <Alert
        class="my-3"
        title="issue.description"
      >
        <RichText
          :opsJson="issue.richTextDescription"
          :rawText="issue.description"
        />
      </Alert>
    </ArticleBody>
    <ArticleText v-if="issue.description !== phase.description">
      <RichText
        :opsJson="phase.richTextDescription"
        :rawText="phase.description"
      />
    </ArticleText>
    <div v-if="phase.isCommunicationPhase">
      <CommunicationPhaseHistoryGroup
        :active-issue="issue"
        :phase-id="phase.id.toString()"
        :readOnly="true"
      />
    </div>
    <div v-else>
      <template
        v-for="step in orderedSteps"
        :key="step.id"
      >
        <template v-if="step.fields.length > 0">
          <p class="mb-1 p--large">
            <Translate
              :value="getPhaseStepKey(issue.process, phase, step)"
              :default-value="step.name"
            />
          </p>
          <template
            v-for="field in step.fields"
            :key="field.key"
          >
            <ArticleText v-if="field.value.length > 0">{{ field.value }}</ArticleText>
          </template>
        </template>
      </template>
      <ArticleHeader class="mb-2">
        <Translate
          t="issue.files"
          :interpolations="{ n: publisedPhaseFiles.length || 0 }"
        />
      </ArticleHeader>
      <p class="mb-2 regular">
        <Translate t="issue.files.help" />
      </p>
      <div class="file-list-container">
        <FileList
          class="mb-2"
          :files="publisedPhaseFiles.map((f) => f.data)"
        />
      </div>
    </div>
    <Button
      :type="ButtonType.secondary"
      :prevent="true"
      @click="onClick"
    >
      <Translate
        t="issue.files.download"
        :interpolations="{ n: publisedPhaseFiles.length || 0 }"
      />
      <Icon
        icon="Download"
        :options="{ color: Colors.blue }"
        class="icon-right"
      />
    </Button>
  </Article>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.file-list-container {
  max-width: 100%;

  @include mq.laptop() {
    max-width: 50%;
  }
}
</style>
