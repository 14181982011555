<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';

withDefaults(
  defineProps<{
    type?: 'expand' | 'show';
  }>(),
  {
    type: 'expand',
  }
);

const emits = defineEmits<{
  (e: 'click'): void;
}>();

const onClick = () => {
  emits('click');
};
</script>
<template>
  <Button @click="onClick" class="cta-button" :type="ButtonType.primary">
    <template v-if="type === 'expand'">
      <Icon icon="Expand" class="icon-left" :options="{ color: Colors.white }" />
      <Translate t="issue.map.expand" />
    </template>
    <template v-else>
      <Translate t="issue.map.show" />
      <Icon icon="ArrowUp" class="cta__arrow icon-right" :options="{ color: Colors.white }" />
    </template>
    <Icon icon="MapGrayscale" class="map-image" :options="{ width: 64, height: 64 }" />
  </Button>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/z-indicies' as z;
@use '@/scss/design-tokens/colors' as colors;

.cta-button {
  padding-right: 0;
  padding-top: 0;
  outline: 1px solid colors.$primary;
  z-index: z.$z-index-map-cta;
}

.map-image {
  margin-left: 2.4rem;
  border-radius: 0.2rem;
  margin-top: auto;
}

.cta__arrow {
  transform: rotate(90deg);
}
</style>
