import parse from 'date-fns/parse';
import format from 'date-fns/format';
import is from 'date-fns/locale/is';
import useModelChecker from './useModelChecker';
import parseJSON from 'date-fns/parseJSON';

type LocaleKey = 'is';
type DateLike = Date | string;

interface UseDateFormatting {
  formatDate: (date: DateLike, formatStr: string, locale?: LocaleKey) => string;
  formatDateLocalized: (date: DateLike, length?: 1 | 2 | 3 | 4, locale?: LocaleKey) => string;
  formatDateTimeLocalized: (date: DateLike, length?: 1 | 2 | 3 | 4, locale?: LocaleKey) => string;

  parseDate: (date: string, formatStr: string, locale?: LocaleKey) => Date;
  parseDateLocalized: (date: string, length?: 1 | 2 | 3 | 4, locale?: LocaleKey) => Date;
  parseDateJson: (date: string | number | Date) => Date;
}

export default function useDateFormatting(): UseDateFormatting {
  const { isDate } = useModelChecker();
  const locales: Record<LocaleKey, Locale> = { is };

  const localizedFormat = (length: 1 | 2 | 3 | 4 = 1) => {
    return 'P'.repeat(length);
  };

  const localizedFormatWithTime = (length: 1 | 2 | 3 | 4 = 1) => {
    return `${localizedFormat(length)}${`p`.repeat(length)}`;
  };

  const formatDate = (date: DateLike, formatStr: string, locale: LocaleKey = 'is') => {
    return format(isDate(date) ? date : new Date(Date.parse(date)), formatStr, { locale: locales[locale] });
  };

  const formatDateLocalized = (date: DateLike, length: 1 | 2 | 3 | 4 = 1, locale: LocaleKey = 'is') => {
    return formatDate(date, localizedFormat(length), locale);
  };

  const formatDateTimeLocalized = (date: DateLike, length: 1 | 2 | 3 | 4 = 1, locale: LocaleKey = 'is') => {
    return formatDate(date, localizedFormatWithTime(length), locale);
  };

  const parseDate = (date: string, formatStr: string, locale: LocaleKey = 'is') => {
    return parse(date, formatStr, new Date(), { locale: locales[locale] });
  };

  const parseDateLocalized = (date: string, length: 1 | 2 | 3 | 4 = 1, locale: LocaleKey = 'is') => {
    return parseDate(date, localizedFormat(length), locale);
  };

  const parseDateJson = (date: string | number | Date): Date => {
    return parseJSON(date);
  };

  return {
    formatDate,
    formatDateLocalized,
    formatDateTimeLocalized,

    parseDate,
    parseDateLocalized,
    parseDateJson,
  };
}
