<script lang="ts" setup>
import { File } from '@/features/files/models';
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import Article, { ArticleBody } from '@/features/theme/base/article';
import InputText from '@/features/theme/base/InputText.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import FileListItem from './FileListItem.vue';
import Modal from '@/features/theme/base/Modal.vue';
import { useUpdateFileMutation } from '@/generated/graphql';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Translate from '@/features/translations/Translate.vue';

withDefaults(
  defineProps<{
    files?: File[];
    download?: boolean;
    deletable?: boolean;
    editable?: boolean;
  }>(),
  {
    files: () => [],
    download: true,
    deletable: false,
    editable: false,
  }
);

const { t } = useI18n();

const { executeMutation: updateFile } = useUpdateFileMutation();

const emits = defineEmits<{
  (e: 'delete', file: File, index: number): void;
  (e: 'edit', name: string, index: number): void;
}>();

const onDeleteClick = async (file: File, index: number) => {
  emits('delete', file, index);
};

const onEditClick = async (name: string, index: number) => {
  emits('edit', name, index);
};

const fileToEdit = ref<File>();

const fileIndex = ref<number>(-1);

const newFileName = ref<string>();

const modalOpen = ref<boolean>(false);

const onModalClose = () => {
  newFileName.value = '';
  modalOpen.value = false;
};

const onEditFile = (file: File, index: number) => {
  fileIndex.value = index;
  modalOpen.value = true;
  fileToEdit.value = file;
};

const onSaveEditedFile = async () => {
  if (fileToEdit.value != undefined && newFileName.value != undefined && newFileName.value != '') {
    onEditClick(newFileName.value, fileIndex.value);
    if (fileToEdit.value.type != undefined) {
      await updateFile({ fileId: fileToEdit.value.id.toString(), newFileName: newFileName.value + fileToEdit.value.type });
      fileToEdit.value.name = newFileName.value + fileToEdit.value.type;
    }
  }
  onModalClose();
};
</script>
<template>
  <ul v-bind="$attrs" class="file-list">
    <div v-for="(file, index) in files" :key="file.id" class="file-list__item mb-1 gap-1">
      <FileListItem class="file-list__btn " :download="download" :file="file" />
      <Button
        v-if="editable"
        v-tooltip="{ content: t('file.edit.tooltip'), theme: 'primary' }"
        prevent
        :type="ButtonType.tertiaryIconOnly"
        @click="() => onEditFile(file, index)"
      >
        <Icon icon="Draw" :options="{ width: 24, height: 24 }" />
      </Button>
      <Button prevent :type="ButtonType.tertiaryIconOnly" @click="() => onDeleteClick(file, index)" v-if="deletable">
        <Icon icon="Delete" :options="{ width: 24, height: 24 }" />
      </Button>
      <div v-if="file.size != null && file.size / 1000000 >= 65">
        <Translate t="file.error.large_file" />
        {{ (file.size / 1000000).toFixed(1) }} MB
      </div>
    </div>
  </ul>
  <Modal :open="modalOpen" @close="onModalClose">
    <Article>
      <ArticleBody>
        <InputText class="mb-2" :label="$t('base.name')" v-model="newFileName" fullwidth />
        <Button @click="onSaveEditedFile">{{ $t('base.save') }}</Button>
      </ArticleBody>
    </Article>
  </Modal>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;

.file-list {
  margin-bottom: spacing.$gutter-medium;
  display: inline-block;
  width: 100%;
}

.file-list__btn {
  flex-grow: 1;
}

.file-list__item {
  display: flex;
}
</style>
