import { computed, unref } from 'vue';
import { isFuture, isPast, parseISO, isToday } from 'date-fns';
import { IssuePhasePillViewModel } from '../models';

export default function useIssuePhaseReviewDates(phase: MaybeRef<Maybe<IssuePhasePillViewModel>>) {
  const reviewStartDate = computed(() => {
    const d = unref(phase)?.reviewStartDate;
    if (d) {
      return parseISO(d);
    }
    return null;
  });

  const reviewEndDate = computed(() => {
    const d = unref(phase)?.reviewEndDate;
    if (d) {
      return parseISO(d);
    }
    return null;
  });

  const reviewStartDatePassed = computed<boolean>(() => {
    if (reviewStartDate.value) {
      return isPast(reviewStartDate.value);
    }

    return false;
  });

  const reviewEndDateNotPassed = computed<boolean>(() => {
    if (reviewEndDate.value) {
      return isToday(reviewEndDate.value) || isFuture(reviewEndDate.value);
    }

    return false;
  });

  const isOpenForReview = computed<boolean>(() => {
    const p = unref(phase);
    return !!p?.hasReviews && p?.state === 'published' && reviewStartDatePassed.value && reviewEndDateNotPassed.value;
  });

  const isReviewPhase = computed<boolean>(() => {
    const p = unref(phase);
    return !!p?.hasReviews && p?.state === 'published';
  });

  return {
    reviewStartDate,
    reviewEndDate,
    isOpenForReview,
    isReviewPhase,
  };
}
