<script lang="ts" setup>
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import { IssuePhaseStepEnum, IssueStepSpecificationInput, useGetPhaseStepDataQuery } from '@/generated/graphql';
import { computed, onUnmounted } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import CommunicationPhaseHistory from './CommunicationPhaseHistory.vue';
import { File as DomainFile } from '@/features/files/models';
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import useActiveIssue from '../../composables/useActiveIssue';

const props = defineProps<{
  activeIssue: IssueDetails;
  phaseId: string;
  readOnly: boolean | undefined;
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onCommentDelete', stepType: IssuePhaseStepEnum, iterationCounter: number): void;
}>();

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onCommentDelete = (stepType: IssuePhaseStepEnum, iterationCounter: number) => {
  emits('onCommentDelete', stepType, iterationCounter);
};

const selectedPhaseId = computed<ID>(() => props.phaseId);
const selectedPhase = computed<Maybe<IssuePhase>>(() => {
  const { findById } = useCollectionSearching();
  if (props.activeIssue && selectedPhaseId.value) {
    const phase = findById(props.activeIssue.phases, selectedPhaseId.value);
    if (phase) {
      return phase;
    }
  }
  return props.activeIssue.currentPhase;
});

const { selectedStep } = useActiveIssue(computed(() => props.activeIssue));

const { data } = useGetPhaseStepDataQuery( { 
  variables: { 
    input: computed<IssueStepSpecificationInput>(() => {
      return {
        issuePhaseId: props.phaseId,
        stepType: selectedStep.value?.type
      }
    }),
    phaseId: computed(() => props.phaseId)
  }, 
  requestPolicy: 'network-only' 
});

const phaseStepData = computed(() => {
  return data.value?.phase.stepData;
});

const prevDataExists = computed(() => {
  return phaseStepData.value?.historicStepData.length != undefined && phaseStepData.value?.historicStepData.length>  0;
})

const stepLabel = (stepName: string): string => {
  return `${props.activeIssue.process.title || 'process'}.${selectedPhase.value?.name || 'phase'}.${stepName}`;
};

let timeoutId: NodeJS.Timeout;
onUnmounted(() => {
  clearTimeout(timeoutId);
});
</script>

<template>  
  <div class="data-history-caption" v-if="prevDataExists.valueOf()">
    <Translate t="issue.phase.step.data_history" />
  </div>
  <LayoutFormGroup v-for="data in phaseStepData?.historicStepData">
    <CommunicationPhaseHistory
      :active-issue="props.activeIssue"
      :step-field="data.stepField?.value"
      :step-files="data.stepFiles.map(x => x.data)"
      :step-label="stepLabel(data.stepName)"
      :step-label-default-value="data.stepName"
      :step-type="data.stepType"
      :registration-date="data.createdAt"
      :read-only="props.readOnly"
      :iteration-counter="data.iterationCounter"
      :published="data.published"
      :published-at="data.publishedAt"
      @on-comment-delete="onCommentDelete"
      @on-file-delete="onFileDelete"
    />
  </LayoutFormGroup>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.data-history-caption {
  padding-top: 1rem;
  font-size: larger;
  color: colors.$primary;
  font-weight: bolder;
  border-top: grey 1px solid;
  margin-bottom: 1rem;
}
</style>
