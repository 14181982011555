<script lang="ts" setup>
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { computed, reactive, ref } from 'vue';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import DropDown from '@/features/theme/base/DropDown.vue';
import Option from '@/features/theme/base/models/Option';
import { IssueDetails, IssuePhase } from '../../models';
import useIssueReviewOperations from '../../composables/useIssueReviewOperations';
import Translate from '@/features/translations/Translate.vue';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import FileList from '@/features/files/components/FileList.vue';
import { File as DomainFile } from '@/features/files/models';
import Spinner from '@/features/theme/base/Spinner.vue';
import Confirm from '@/features/theme/base/Confirm.vue';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import { AddReviewInput } from '../../models/AddReviewInput';
import { useAccessControl } from '@/features/auth/useAccessControl';
import { unref } from 'vue';
import RichTextEditor from '@/features/theme/base/richTextEditor/RichTextEditor.vue';
import { Delta } from '@vueup/vue-quill';

const props = defineProps<{
  phase: IssuePhase;
  issue: IssueDetails;
  isPrivate?: boolean;
}>();

const emits = defineEmits<{
  (e: 'submit'): void;
  (e: 'cancel'): void;
  (e: 'onNewFileEdit', name: string, index: number): void;
}>();

const input = reactive<AddReviewInput>({
  title: '',
  comment: '',
  richTextComment: '',
  extraRequiredReviewerId: null,
  statutoryReviewerId: null,
  files: [],
});

const submitDisabled = ref(false);
const { addReviewToIssuePhase } = useIssueReviewOperations();

const showStatutoryReviewerEmptyWarning = ref(false);

const showSubmitReviewError = ref(false);
const { canEditIssue } = useAccessControl();
const issueId = computed(() => {
  return props.phase.issueId;
});

const activeIssue = computed(() => props.issue);

const onSubmit = async () => {
  if (props.isPrivate && !input.statutoryReviewerId && !canEditIssue(unref(activeIssue)!)) {
    showStatutoryReviewerEmptyWarning.value = true;
    return;
  }

  try {
    submitDisabled.value = true;
    if (delta.value?.ops) {
      const opsJson = JSON.stringify(delta.value?.ops);
      input.richTextComment = opsJson;
      input.comment = delta.value.ops.map(op => (typeof op.insert === 'string' ? op.insert : '')).join('');
    }

    await addReviewToIssuePhase(props.phase.issueId, props.phase.id, input);
    delta.value = null;
    input.title = '';
    input.comment = '';
    input.richTextComment = '';
    input.extraRequiredReviewerId = null;
    input.statutoryReviewerId = null;
    input.files = [];
    reviewer.value = null;
    emits('submit');
  } catch (error) {
    showSubmitReviewError.value = true;
    console.error(error);
  } finally {
    submitDisabled.value = false;
  }
};

const onCancelClick = () => {
  delta.value = null;
  input.title = '';
  input.comment = '';
  input.richTextComment = '';
  input.extraRequiredReviewerId = null;
  input.statutoryReviewerId = null;
  input.files = [];
  reviewer.value = null;

  emits('cancel');
};

const reviewerOptions = computed<Option[]>(() => {
  const requiredReviewers: Option[] = props.phase.requiredReviewers.map(reviewer => {
    return {
      value: reviewer.id.toString(),
      name: reviewer.name,
    };
  });
  return requiredReviewers.concat(
    props.phase.extraRequiredReviewers.map(reviewer => {
      return {
        value: `EXTRA_${reviewer.id.toString()}`,
        name: reviewer.name,
      };
    })
  );
});

const reviewer = ref<Maybe<string>>();

const onReviewerUpdate = (v: string) => {
  reviewer.value = v;

  if (v.startsWith('EXTRA')) {
    input.extraRequiredReviewerId = v.split('_')[1];
    input.statutoryReviewerId = null;
  } else {
    input.extraRequiredReviewerId = null;
    input.statutoryReviewerId = v;
  }
};

const isRequiredReviewer = ref<boolean>(false);

const onFilesInput = (payload: Base64FilePayload[]) => {
  for (const item of payload) {
    input.files?.push({
      data: item.base64,
      name: item.file.name,
      encoding: 1,
    });
  }
};

const inputFiles = computed<DomainFile[]>(() => {
  return (
    input.files?.map((file, index) => {
      return { id: index, path: file.name, name: file.name, type: file.name.split('.')[-1] };
    }) || []
  );
});

const onFileDelete = (_: DomainFile, index: number) => {
  input.files?.splice(index, 1);
};

const onNewFileEdit = (name: string, index: number) => {
  if (input.files) {
    var type = input.files[index].name.split('.')[1];
    input.files[index].name = name + '.' + type;
  }
};

const delta = ref<Delta | null>(null);

const rteCharacterCount = computed(() => {
  return delta.value?.length ? delta.value.reduce((acc, d) => (typeof d.insert === 'string' ? acc + d.insert.length : acc), 0) : 0;
});

const onRichTextUpdate = (next: Maybe<Delta>) => {
  if (next) {
    delta.value = next;
  }
};
</script>
<template>
  <form @submit.prevent="onSubmit">
    <LayoutForm>
      <template #header>
        <div class="form-header">
          <div class="form-text">
            <h5 class="h5 text-primary"><Translate t="issue.review.header" /></h5>
            <p class="regular">
              <Translate t="issue.review.header.help" />
            </p>
          </div>
        </div>
      </template>
      <LayoutFormGroup>
        <LayoutInput :span="3">
          <RichTextEditor
            translationKey="issue.review.write"
            :model-value="delta"
            @update:model-value="val => onRichTextUpdate(val)"
            fullwidth
          />
          <div class="number_count grey-600">
            <Translate
              t="base.charlimit"
              :interpolations="{
                n: rteCharacterCount,
                max: 2500,
              }"
            />
          </div>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup v-if="!isPrivate">
        <Checkbox
          v-model="isRequiredReviewer"
          id="required-reviewer"
        >
          <Translate t="issue.review.reviewer_check" />
        </Checkbox>
      </LayoutFormGroup>
      <LayoutFormGroup v-if="isRequiredReviewer || isPrivate">
        <LayoutInput :span="2">
          <div class="form-reviewer">
            <DropDown
              label="issue.review.reviewer"
              fullwidth
              v-if="reviewerOptions.length > 0"
              :options="reviewerOptions"
              :model-value="reviewer"
              @update:model-value="onReviewerUpdate"
            />
          </div>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput :span="3">
          <div>
            <h6 class="h6 mb-1">
              <Translate t="issue.review.files" />
            </h6>
            <p class="regular">
              <Translate t="issue.review.files.help" />
            </p>
          </div>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup class="mb-4">
        <LayoutInput>
          <FileInputBase64 @files="onFilesInput" />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <FileList
            :files="inputFiles"
            :download="false"
            editable
            deletable
            @delete="onFileDelete"
            @edit="onNewFileEdit"
          />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput :span="3">
          <p class="regular">
            <Translate t="issue.review.warning" />
          </p>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput :span="3">
          <div class="button-group">
            <Button
              :disabled="submitDisabled"
              :type="ButtonType.primary"
            >
              <Translate
                v-if="!submitDisabled"
                t="issue.review.submit"
              />
              <Spinner v-else />
            </Button>
            <Button
              @click="onCancelClick"
              prevent
              :type="ButtonType.secondary"
            >
              <Translate t="base.cancel" />
            </Button>
          </div>
        </LayoutInput>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
  <div>
    <Confirm
      :active="showStatutoryReviewerEmptyWarning"
      :type="'warning'"
      @ok="() => (showStatutoryReviewerEmptyWarning = false)"
    >
      <Alert
        class="alert"
        title="base.warning"
        :alert-type="AlertType.danger"
      >
        <Translate t="issue.review.statutory_reviewer_required" />
      </Alert>
    </Confirm>
  </div>
  <div>
    <Confirm
      :active="showSubmitReviewError"
      :type="'warning'"
      @ok="() => (showSubmitReviewError = false)"
    >
      <Alert
        class="alert"
        title="base.warning"
        :alert-type="AlertType.danger"
      >
        <Translate t="issue.review.submit.error" />
      </Alert>
    </Confirm>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;

.button-group {
  display: flex;
  gap: spacing.$gutter-large;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-text {
  flex: 3 1 auto;
}

.form-reviewer {
  flex: 2 1 auto;
}

.number_count {
  margin-top: 0.8rem;
  text-align: right;
}
</style>
