<script lang="ts" setup>
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import TextArea from '@/features/theme/base/TextArea.vue';
import { IssuePhaseStepEnum } from '@/generated/graphql';
import useDateFormatting from '@/features/composables/useDateFormatting';
import Translate from '@/features/translations/Translate.vue';
import FileList from '@/features/files/components/FileList.vue';
import { File as DomainFile } from '@/features/files/models';
import { useI18n } from 'vue-i18n';
import useActiveIssue from '../../composables/useActiveIssue';
import { computed } from 'vue';
import { IssueDetails } from '@/features/issues/models';
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import useCommunicationStep from '../../composables/useCommunicationStep';

const props = defineProps<{
  activeIssue: IssueDetails;
  stepType: IssuePhaseStepEnum;
  stepLabel: string;
  stepLabelDefaultValue?: string;
  stepFiles: DomainFile[];
  stepField: string | undefined;
  registrationDate: string | Date;
  readOnly: boolean | undefined;
  iterationCounter: number;
  published: boolean;
  publishedAt?: string | Date;
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onCommentDelete', stepType: IssuePhaseStepEnum, iterationCounter: number): void;
}>();

const activeIssue = computed(() => props.activeIssue);

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onCommentDelete = () => {
  emits('onCommentDelete', props.stepType, props.iterationCounter);
};

const { t } = useI18n();

const { formatDateLocalized } = useDateFormatting();

const { selectedStep, selectedPhase } = useActiveIssue(activeIssue);

const { isComPhaseAdmin, isSLSStep } = useCommunicationStep(activeIssue);

const canEdit = computed<boolean>(() => {
  return !props.readOnly && selectedPhase.value?.state !== 'closed' && props.stepType == selectedStep.value?.type;
});

const canDelete = computed(() => {
  return canEdit.value && !(isSLSStep.value && !isComPhaseAdmin.value);
});
</script>

<template>
  <LayoutInput
    v-if="!readOnly || (readOnly && published)"
    :span="2"
    :offset="stepType === IssuePhaseStepEnum.TillagaTilAthugunar || stepType === IssuePhaseStepEnum.GognTilStadfestingar ? 0 : 1"
  >
    <div class="mb-1">
      <div class="reg-header">
        <p>
          <Translate
            :value="stepLabel"
            :default-value="stepLabelDefaultValue ? t(stepLabelDefaultValue) : undefined"
          />
        </p>
        <p
          v-if="!published"
          class="color-orange--dark"
        >
          <Translate t="issue.phase.step.data_not_published" />
        </p>
        <p v-else>
          <Translate
            v-if="publishedAt"
            t="issue.phase.step.data_published_date"
            :interpolations="{ date: formatDateLocalized(publishedAt) }"
          />
        </p>
      </div>
    </div>
    <div
      v-if="stepField"
      class="mb-1 editable-textbox"
    >
      <TextArea
        :custom-class="'textarea_history'"
        :rows="4"
        :model-value="stepField"
        :deletable="canDelete"
        fullwidth
        disabled
      />
      <Button
        v-if="canDelete"
        prevent
        style="align-self: flex-end"
        :type="ButtonType.tertiaryIconOnly"
        @click="() => onCommentDelete()"
      >
        <Icon
          icon="Delete"
          :options="{ width: 24, height: 24 }"
        />
      </Button>
    </div>
    <div class="mb-1">
      <FileList
        :files="stepFiles"
        :editable="canDelete"
        :deletable="canDelete"
        @delete="onFileDelete"
      />
    </div>
  </LayoutInput>
</template>

<style lang="scss" scoped>
.reg-header {
  color: grey;
  font-weight: normal;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.editable-textbox {
  display: flex;
  gap: 1rem;
}
</style>
