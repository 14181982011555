import { computed, unref } from 'vue';
import { IssuePhasePillViewModel } from '../models';
import useIssuePhaseReviewDates from '../composables/useIssuePhaseReviewDates';
import { IssuePhaseStepEnum, IssueProcessEnum, IssueStatusEnum } from '@/generated/graphql';

export default function useIssuePhaseState(
  issue: MaybeRef<Maybe<{ isHidden?: Maybe<boolean>; process?: { type?: IssueProcessEnum }}>>,
  phase?: MaybeRef<Maybe<IssuePhasePillViewModel>>,
  type?: MaybeRef<Maybe<IssueStatusEnum>>
) {
  const { isOpenForReview } = useIssuePhaseReviewDates(phase);
  const issueOpenForReview = computed(() => {
    const t = unref(type);
    return isOpenForReview.value || t === IssueStatusEnum.Reviewing || t == IssueStatusEnum.InReviewProcedure;
  });

  const issueClosed = computed(() => {
    const p = unref(phase);
    return (p?.state === 'closed' && p?.isLast) || unref(type) === IssueStatusEnum.Complete;
  });

  const issuePhaseClosed = computed(() => {
    const p = unref(phase);
    return p?.state === 'closed';
  });

  const issueInSlsReview = computed(() => {
    const p = unref(phase);
    const i = unref(issue);
    return (
      !issuePhaseClosed.value && 
      (p?.currentStep?.type === IssuePhaseStepEnum.UmsognSkipulagsstofnunar || 
      p?.currentStep?.type === IssuePhaseStepEnum.StadfestingSkipulagsstofnunar) &&
      !(i?.process?.type === IssueProcessEnum.NyttStrandsvaedisskipulag ||
        i?.process?.type === IssueProcessEnum.BreytingAStrandsvaedisskipulagi ||
        i?.process?.type === IssueProcessEnum.MatAUmhverfisahrifum ||
        i?.process?.type === IssueProcessEnum.TilkynningTilAkvordunarUmMatsskyldu
      )
    ) || unref(type) === IssueStatusEnum.InSlsReview;
  });

  const issueUnpublished = computed(() => unref(issue)?.isHidden || unref(type) === IssueStatusEnum.Unpublished);
  
  const issuePhaseStateString = computed(() => {
    if (issueUnpublished.value) {
      return 'issue.phase.state.unpublished';
    }
    if (issueOpenForReview.value) {
      const p = unref(phase);
      const t = unref(type);
      const hasReviewProcedure = t === IssueStatusEnum.InReviewProcedure || p?.hasReviewProcedure;
      return hasReviewProcedure ? 'issue.phase.state.in_review_procedure' : 'issue.phase.state.reviewing';
    } else if (issueClosed.value) {
      return 'issue.phase.state.closed';
    } else if (issueInSlsReview.value) {
      return 'issue.phase.state.in_sls_review';
    }
    return 'issue.phase.state.published';
  });

  const issueStatusEnum = computed(() => {
    if (issueUnpublished.value) {
      return IssueStatusEnum.Unpublished;
    } else if (issueOpenForReview.value) {
      const p = unref(phase);
      return p?.hasReviewProcedure ? IssueStatusEnum.InReviewProcedure : IssueStatusEnum.Reviewing;
    } else if (issueClosed.value) {
      return IssueStatusEnum.Complete;
    } else if (issueInSlsReview.value) {
      return IssueStatusEnum.InSlsReview;
    }
    return IssueStatusEnum.InProgress;
  });

  return {
    issueOpenForReview,
    issueClosed,
    issuePhaseClosed,
    issuePhaseStateString,
    issueUnpublished,
    issueStatusEnum,
    issueInSlsReview
  };
}
