<script lang="ts" setup>
import IssueActivePhase from './activeIssue/IssueActivePhase.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import IssueActivePhaseTabs from './activeIssue/IssueActivePhaseTabs.vue';
import IssueActiveStepTabs from './activeIssue/IssueActiveStepTabs.vue';
import { useRouter } from 'vue-router';
import IssueAside from '@/features/issues/components/issueDetails/IssueAside.vue';
import useNavigationStore from '@/features/navigation/useNavigationStore';
import LayoutWithAside from '@/features/theme/base/layouts/LayoutWithAside.vue';
import useProcessObservers from '../composables/useProcessObservers';
import { ProcessOperation, useProcessStore } from '../composables/useProcessStore';
import { computed, ref } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import Modal from '@/features/theme/base/Modal.vue';
import Spinner from '@/features/theme/base/Spinner.vue';
import Card from '@/features/theme/base/Card.vue';
import useDownloadFiles from '@/features/files/composables/useDownloadFiles';
import useActiveIssueOperations from '../composables/useActiveIssueOperations';
import useActivePhaseOperations from '../composables/useActivePhaseOperations';
import { useI18n } from 'vue-i18n';
import MainMenu from '@/features/navigation/MainMenu.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import useAuthStore from '@/features/auth/useAuthStore';
import { IssuePhaseStepEnum, Paragraph, useDeleteIssueMutation } from '@/generated/graphql';
import Confirm from '@/features/theme/base/Confirm.vue';
import useActiveIssue from '../composables/useActiveIssue';
import { usePushIssueYearOrderRoute } from '@/features/issues/composables/usePushIssueYearOrder';
import { IssueDetails } from '@/features/issues/models';
import IssueSaveWarning from '@/features/issues/components/IssueSaveWarning.vue';

const { pushIssueYearOrderRoute } = usePushIssueYearOrderRoute();

const props = defineProps<{
  issue: IssueDetails;
  paragraph?: Paragraph;
}>();

const activeIssue = computed(() => props.issue);

const { basicInfoPhaseSelected, selectedPhaseSteps, selectPhase, selectStep, activePhase, selectedPhase, selectedStep } = useActiveIssue(activeIssue);

const { triggerProcessObservers } = useProcessObservers();
const { isAdmin } = useAuthStore();

const triggerSave = async (autosave: boolean = false, updateSelectedStep: boolean = false) => {
  try {
    await triggerProcessObservers(ProcessOperation.Save, { autosave, updateSelectedStep });
  } catch (e) {
    console.error(e);
  } finally {
  }
};

const loading = ref<boolean>(false);
const onShowClick = () => {
  if (activeIssue.value) {
    pushIssueYearOrderRoute(activeIssue.value);
  }
};

const { getArchiveIssueUrl, downloadFileUrl, fetchFileObjectUrl } = useDownloadFiles();

const onArchiveIssueClick = async () => {
  if (activeIssue.value) {
    const url = getArchiveIssueUrl(activeIssue.value.id);
    const objectUrl = await fetchFileObjectUrl(url);
    downloadFileUrl(objectUrl, `[${activeIssue.value.issueNumber}] ${activeIssue.value.title}.zip`);
  }
};

const { executeMutation: deleteIssue } = useDeleteIssueMutation();
const router = useRouter();

const confirmDelete = ref<boolean>(false);

const onDeleteIssueClick = async () => {
  confirmDelete.value = true;
};

const onConfirmDelete = async () => {
  confirmDelete.value = false;
  if (activeIssue.value) {
    await deleteIssue({ issueId: activeIssue.value.id.toString() });
    router.replace('/issues');
  }
};

const onCancelDelete = async () => {
  confirmDelete.value = false;
};

// Find out if user is on last step of last phase
const isClosingIssue = computed(() => {
  if (activeIssue.value) {
    const currentPhaseOrder = activeIssue.value.currentPhase?.order;
    const lastPhaseIndex = activeIssue.value.phases.length - 1;
    const lastPhaseOrder = activeIssue.value?.phases[lastPhaseIndex].order;
    return currentPhaseOrder === lastPhaseOrder;
  }
  return false;
});

const caseClosed = ref(false);

const onActivePhaseClose = () => {
  triggerProcessObservers(ProcessOperation.Close)
    .then(() => {
      if (isClosingIssue.value) {
        caseClosed.value = true;
      }
    })
    .catch(e => {
      console.error(e);
    });
};

const { startNextPhaseForActiveIssue, startPreviousPhaseForActiveIssue, updateActiveIssueInfo } = useActiveIssueOperations(activeIssue.value.id);
const onActivePhaseNext = async () => {
  try {
    loading.value = true;
    await triggerProcessObservers(ProcessOperation.Save);
    await triggerProcessObservers(ProcessOperation.Step);
    const issueDetails = await startNextPhaseForActiveIssue();
    selectPhase(issueDetails.currentPhase?.id);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const onActivePhasePrev = async () => {
  loading.value = true;
  const issueDetails = await startPreviousPhaseForActiveIssue();
  try {
    selectPhase(issueDetails.currentPhase?.id);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const { moveActivePhaseStep, moveActivePhasePrevStep, moveCommunicationPhaseStep } = useActivePhaseOperations(activeIssue);

const onActivePhaseStep = async (stepId: ID) => {
  try {
    loading.value = true;
    await triggerProcessObservers(ProcessOperation.Save);
    await triggerProcessObservers(ProcessOperation.Step);
    await moveActivePhaseStep(stepId);
    selectStep(stepId);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const onActivePhasePrevStep = async (stepId: ID) => {
  try {
    loading.value = true;
    await triggerProcessObservers(ProcessOperation.Save);
    await triggerProcessObservers(ProcessOperation.Step);
    if (activePhase.value?.isCommunicationPhase) {
      await moveActivePhaseStep(stepId);
    } else {
      await moveActivePhasePrevStep(stepId);
    }
    selectStep(stepId);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const onCommunicationPhaseStep = async (selectedStepType: IssuePhaseStepEnum) => {
  try {
    loading.value = true;
    await triggerProcessObservers(ProcessOperation.Save);
    await triggerProcessObservers(ProcessOperation.Step);
    var res = await moveCommunicationPhaseStep(selectedStepType);
    if (res.currentStep) {
      selectStep(res.currentStep.id);
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const { t } = useI18n();

const navStore = useNavigationStore();

navStore.setMainMenuTheme('default');

const onActivePhasePublish = async () => {
  try {
    loading.value = true;
    await updateActiveIssueInfo({ isHidden: false });
    await triggerProcessObservers(ProcessOperation.Publish);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const onPublishClick = async () => {
  try {
    loading.value = true;
    await updateActiveIssueInfo({ isHidden: false });
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const onHideClick = async () => {
  try {
    loading.value = true;
    await updateActiveIssueInfo({ isHidden: true });
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const onActivePhaseDraft = async () => {
  try {
    loading.value = true;
    await triggerProcessObservers(ProcessOperation.Draft);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const noticeText = computed(() => {
  if (activeIssue.value?.isHidden) {
    return 'issue.hidden';
  }

  return 'issue.visible';
});
const noticeAlertType = computed(() => {
  if (activeIssue.value?.isHidden) {
    return AlertType.warning;
  }

  return AlertType.success;
});

const onActivePhaseSave = () => {
  triggerSave(false, true);
};

const onStepTabChange = () => {
  triggerSave(false, false);
};

const warningPrompt = ref<boolean>(false);
const processStore = useProcessStore();
const destinationPhase = ref<ID>('');
const destinationStep = ref<ID>('');

const onContinue = () => {
  warningPrompt.value = false;
  processStore.isDirty = false;

  selectDestination();
};

const onSave = async () => {
  warningPrompt.value = false;
  processStore.isDirty = false;
  await triggerSave(false, true);

  selectDestination();
};

const onCancel = () => {
  warningPrompt.value = false;
};

const selectDestination = () => {
  if (destinationStep.value) {
    selectStep(destinationStep.value);
  } else if (destinationPhase.value) {
    selectPhase(destinationPhase.value);
  }

  destinationPhase.value = '';
  destinationStep.value = '';
};

const setDestinationPhase = (phaseID: ID) => {
  warningPrompt.value = true;
  destinationPhase.value = phaseID;
};

const setDestinationStep = (stepID: ID) => {
  warningPrompt.value = true;
  destinationStep.value = stepID;
};
</script>

<template>
  <div v-bind="$attrs">
    <div>
      <div class="process-header">
        <div class="process-container">
          <IssueActivePhaseTabs
            :active-issue="activeIssue"
            @triggerIssueSaveWarning="setDestinationPhase"
            @tab-change="onStepTabChange"
          />
          <div class="button-toolbar">
            <div class="button-icons">
              <Button
                @click="onArchiveIssueClick"
                :type="ButtonType.tertiaryIconOnly"
                :disabled="!activeIssue.currentPhase"
                v-tooltip.bottom="{ content: t('issue.files.tooltip'), theme: 'primary' }"
              >
                <Icon
                  icon="Inbox"
                  :options="{ width: 24, height: 24, color: Colors.primary }"
                />
              </Button>
              <Button
                @click="onShowClick"
                :type="ButtonType.tertiaryIconOnly"
                :disabled="!activeIssue.currentPhase"
                v-tooltip.bottom="{ content: t('issue.view.tooltip.view'), theme: 'primary' }"
              >
                <Icon
                  icon="Review"
                  :options="{ width: 24, height: 24, color: Colors.primary }"
                />
              </Button>
              <Button
                v-if="isAdmin"
                @click="onDeleteIssueClick"
                :type="ButtonType.tertiaryIconOnly"
                v-tooltip.bottom="{ content: t('issue.view.tooltip.delete'), theme: 'primary' }"
              >
                <Icon
                  icon="Delete"
                  :options="{ width: 24, height: 24, color: Colors.primary }"
                />
              </Button>
            </div>
            <MainMenu
              v-if="navStore.activeArea"
              :nav="navStore.activeArea.navigation"
              :theme="navStore.mainMenuTheme"
            />
          </div>
        </div>
        <div class="step-header">
          <IssueActiveStepTabs
            :active-issue="activeIssue"
            @triggerIssueSaveWarning="setDestinationStep"
            @tab-change="onStepTabChange"
            v-if="selectedPhaseSteps.length > 0 && !basicInfoPhaseSelected"
          />
        </div>
      </div>
      <LayoutWithAside>
        <Alert :alert-type="noticeAlertType">
          <div class="flex flex-align-center gap-2">
            <Translate :t="noticeText" />
            <span v-if="activeIssue.currentPhase">
              <a
                @click.prevent="onPublishClick"
                v-if="activeIssue.isHidden"
              >
                <Translate t="issue.publish_click" />
              </a>
              <a
                @click.prevent="onHideClick"
                v-else-if="isAdmin"
              >
                <Translate t="issue.hide_click" />
              </a>
            </span>
          </div>
        </Alert>
        <IssueActivePhase
          :active-issue="activeIssue"
          @close="onActivePhaseClose"
          @next="onActivePhaseNext"
          @prev="onActivePhasePrev"
          @step="onActivePhaseStep"
          @prev-step="onActivePhasePrevStep"
          @communication-step="onCommunicationPhaseStep"
          @publish="onActivePhasePublish"
          @save="onActivePhaseSave"
          @draft="onActivePhaseDraft"
        />
        <template #aside>
          <IssueAside
            :issue="activeIssue"
            :editing="true"
          />
        </template>
      </LayoutWithAside>
    </div>
    <Modal
      :open="loading"
      :closable="false"
    >
      <Card>
        <div class="loading-card">
          <Spinner />
          <Translate t="base.saving" />
        </div>
      </Card>
    </Modal>
    <Modal
      :open="!loading && caseClosed"
      @close="() => (caseClosed = false)"
    >
      <Card>
        <template #default>
          <div class="p-4">
            <span class="h6 color-primary bold">
              <Translate t="issue.phase.state.finalized" />
            </span>
          </div>
        </template>
      </Card>
    </Modal>
  </div>
  <Confirm
    :active="confirmDelete"
    @yes="onConfirmDelete"
    @no="onCancelDelete"
  >
    <Alert
      class="alert"
      title="base.warning"
      :alert-type="AlertType.warning"
    >
      <Translate value="issue.view.tooltip.delete.warning" />
    </Alert>
  </Confirm>
  <IssueSaveWarning
    :prompt="warningPrompt"
    @continue="onContinue"
    @save="onSave"
    @cancel="onCancel"
  ></IssueSaveWarning>
</template>
<style lang="scss">
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;

.background-save-spinner {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
}

.loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process-header {
  background-color: colors.$primary--xlight;

  .process-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 4.8rem 1.8rem 2rem;

    @include mq.laptop-down() {
      flex-direction: column;
      padding: 1.8rem 2.4rem 1.8rem 1rem;
    }
  }
}

.process-tabs {
  display: flex;
}

.process-tab {
  padding: 0.8rem;
}

.step-header {
  display: flex;
  align-items: center;
  // height: 6.3rem;
  margin-bottom: 8rem;
  background-color: white;

  .tab {
    position: relative;
    display: flex;
    align-items: center;
    height: 6.3rem;
    margin-right: 8rem;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0.3rem;
        width: 100%;
        background-color: colors.$primary;
      }
    }
  }
}

.step-tabs {
  display: flex;
}

.step-tab {
  padding: 0.8rem;
}

.button-toolbar {
  display: flex;
  align-items: center;
  gap: 2rem;

  @include mq.laptop-down() {
    margin-top: 2rem;
    width: 100%;
    justify-content: flex-end;
  }
}

.button-icons {
  display: flex;
  gap: 1rem;

  @include mq.laptop-down() {
    display: none;
  }
}

.button-tool {
  margin-left: 4rem;

  @include mq.laptop-down() {
    margin-left: unset;
    width: 100%;
  }
}

.button-text {
  margin-right: 1.4rem;
}
</style>
